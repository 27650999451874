// extracted by mini-css-extract-plugin
export var primaryHeader = "managedInfrastructure-module--primaryHeader--ErTe2";
export var secondaryHeader = "managedInfrastructure-module--secondaryHeader--L3j8f";
export var innerRowContainer = "managedInfrastructure-module--innerRowContainer--eag-f";
export var innerColumnContainer = "managedInfrastructure-module--innerColumnContainer--HoQn4";
export var callBackButton = "managedInfrastructure-module--callBackButton--8nrwU";
export var sectionHeader = "managedInfrastructure-module--sectionHeader--5+B22";
export var customersHeader = "managedInfrastructure-module--customersHeader--dmI5S";
export var servicesHeader = "managedInfrastructure-module--servicesHeader--d5C2W";
export var clientsContainer = "managedInfrastructure-module--clientsContainer--F5QBH";
export var clientImgWrapper = "managedInfrastructure-module--clientImgWrapper--wYY-D";
export var clientLogo = "managedInfrastructure-module--clientLogo--yUVKy";
export var servicesWrapper = "managedInfrastructure-module--servicesWrapper--Cg9qJ";
export var titleWrapper = "managedInfrastructure-module--titleWrapper--KrdoG";
export var serviceImageWrapper = "managedInfrastructure-module--serviceImageWrapper--YVS9d";
export var serviceLogo = "managedInfrastructure-module--serviceLogo--xE3K2";
export var challengesWrapper = "managedInfrastructure-module--challengesWrapper--cHCtu";
export var challengesTitleWrapper = "managedInfrastructure-module--challengesTitleWrapper--+QkX8";
export var tabSection = "managedInfrastructure-module--tabSection--u0egQ";
export var tabList = "managedInfrastructure-module--tabList--s8zu1";
export var tab = "managedInfrastructure-module--tab--Mt5cu";
export var tabPanel = "managedInfrastructure-module--tabPanel--eitvt";
export var tabPanelFlex = "managedInfrastructure-module--tabPanelFlex--7y8yz";
export var tabSubheader = "managedInfrastructure-module--tabSubheader--iRnw1";
export var tabContentLeft = "managedInfrastructure-module--tabContentLeft--KF75f";
export var tabContentRight = "managedInfrastructure-module--tabContentRight--KlChd";
export var capabilitiesSubheader = "managedInfrastructure-module--capabilitiesSubheader--HSrET";
export var capabilitiesImageWrapper = "managedInfrastructure-module--capabilitiesImageWrapper--UZSlc";
export var capabilitiesIcon = "managedInfrastructure-module--capabilitiesIcon--JUwfZ";
export var approachSection = "managedInfrastructure-module--approachSection--n1xHm";
export var approachTitleWrapper = "managedInfrastructure-module--approachTitleWrapper--J0hzA";
export var approachBlocks = "managedInfrastructure-module--approachBlocks--k4l5p";
export var approachBlock = "managedInfrastructure-module--approachBlock--5wnTB";
export var differenceSection = "managedInfrastructure-module--differenceSection--zWkE8";
export var differenceBlock = "managedInfrastructure-module--differenceBlock--TB9sp";
export var transformationSection = "managedInfrastructure-module--transformationSection--fnKHp";
export var transformationContainer = "managedInfrastructure-module--transformationContainer--Ozs62";
export var transformationContent = "managedInfrastructure-module--transformationContent--kDLre";