// extracted by mini-css-extract-plugin
export var wrapper = "home-module--wrapper--DZvTG";
export var homepageTextBanner = "home-module--homepageTextBanner--0p6G1";
export var homepageTextBannerContainer = "home-module--homepageTextBannerContainer--v1kx5";
export var hero = "home-module--hero--R7A7A";
export var heroSubtitle = "home-module--heroSubtitle--JtnWx";
export var heroSubtext = "home-module--heroSubtext--5a7OZ";
export var heroTextContainer = "home-module--heroTextContainer--bd+4+";
export var heroLinks = "home-module--heroLinks--m+NgE";
export var heroLinksSpacer = "home-module--heroLinksSpacer--q6kU8";
export var heroWhatsappButton = "home-module--heroWhatsappButton--65E8b";
export var heroAboutGoogleText = "home-module--heroAboutGoogleText--NTuqV";
export var heroImageDesktop = "home-module--heroImageDesktop--Kwl9O";
export var heroImageMobile = "home-module--heroImageMobile--S5Ce1";
export var goingGoogle = "home-module--goingGoogle--AlJOw";
export var goingGoogleContainer = "home-module--goingGoogleContainer--LB+pW";
export var goingGoogleHeader = "home-module--goingGoogleHeader--hMEld";
export var goingGoogleSubheader = "home-module--goingGoogleSubheader--hD-Mg";
export var goingGoogleSections = "home-module--goingGoogleSections--zRSmh";
export var goingGoogleSection = "home-module--goingGoogleSection--AY3xj";
export var goingGoogleSectionInfo = "home-module--goingGoogleSectionInfo--Y5xdq";
export var goGoogleButtonContainer = "home-module--goGoogleButtonContainer--J1knh";
export var goGoogleButton = "home-module--goGoogleButton--leKJM";
export var horizontalWrapper = "home-module--horizontalWrapper--Zy8yT";
export var verticalContainer = "home-module--verticalContainer--ayri1";
export var verticalContainerInner = "home-module--verticalContainerInner--5wPOB";
export var contentContainer = "home-module--contentContainer--KvCMN";
export var clientWrapper = "home-module--clientWrapper--ArED7";
export var clientsContainer = "home-module--clientsContainer--ZkwUB";
export var clientHeaderWrapper = "home-module--clientHeaderWrapper--kK4oM";
export var clientsubHeaderWrapper = "home-module--clientsubHeaderWrapper--Ec1Gm";
export var contentWrapper = "home-module--contentWrapper--uaAxO";
export var testimonialSection = "home-module--testimonialSection--++xXS";
export var testimonialContentWrapper = "home-module--testimonialContentWrapper--+D+88";
export var testimonialContent = "home-module--testimonialContent--UkN-4";
export var clientTestimonialIndex = "home-module--clientTestimonialIndex--fojsi";
export var homebtn = "home-module--homebtn--wSJhu";
export var imgContainer = "home-module--imgContainer--JqQfK";
export var clientLogo = "home-module--clientLogo--vVp3f";
export var goingGoogleImageContainer = "home-module--goingGoogleImageContainer--Hs5Fb";
export var whatSetsUsApartImage = "home-module--whatSetsUsApartImage--4I6ki";
export var customerSuccessImageWrapper = "home-module--customerSuccessImageWrapper--DgbOR";
export var engineeringStack = "home-module--engineeringStack--lkbXU";
export var aboutUsMap = "home-module--aboutUsMap--3KF5l";
export var ourStrategicPartners = "home-module--ourStrategicPartners--0pcd8";
export var testimonialDescription = "home-module--testimonialDescription--R6eC6";
export var clientImgWrapper = "home-module--clientImgWrapper--MxO28";
export var testimonialSender = "home-module--testimonialSender--t92pH";
export var testimonialSenderPosition = "home-module--testimonialSenderPosition--Ikzcj";
export var sectionWrapper = "home-module--sectionWrapper--tzO4m";
export var professionalServicesWrapper = "home-module--professionalServicesWrapper--OHBGu";
export var sectionContainer = "home-module--sectionContainer--Xd1hz";
export var sectionHeader = "home-module--sectionHeader--z0COI";
export var sectionSubHeader = "home-module--sectionSubHeader--O07x7";
export var professionalServicesDesc = "home-module--professionalServicesDesc--TCn5V";
export var aboutUsDescription = "home-module--aboutUsDescription--xWB8c";
export var professionalServicesCardContainer = "home-module--professionalServicesCardContainer--RBiWB";
export var professionalServiceCard = "home-module--professionalServiceCard--My2-Z";
export var cardHeaderContainer = "home-module--cardHeaderContainer--6dmug";
export var cardHeader = "home-module--cardHeader--y-55x";
export var professionalServicesList = "home-module--professionalServicesList--NsV7q";
export var customerSuccessButtonWrapper = "home-module--customerSuccessButtonWrapper--0b9T+";
export var professionalServiceButtonWrapper = "home-module--professionalServiceButtonWrapper--tjY-E";
export var primaryButton = "home-module--primaryButton--2b4PJ";
export var whyDeimosWrapper = "home-module--whyDeimosWrapper---RvnJ";
export var whyDeimosHeaderContainer = "home-module--whyDeimosHeaderContainer--O7B+q";
export var whyDeimosHeader = "home-module--whyDeimosHeader--9Y9wD";
export var whyDeimosSubHeader = "home-module--whyDeimosSubHeader--22FQA";
export var whyDeimosBodyContainer = "home-module--whyDeimosBodyContainer--95Nt9";
export var whyDeimosBeforeContainer = "home-module--whyDeimosBeforeContainer--M1Ijl";
export var whyDeimosBefore = "home-module--whyDeimosBefore--CTCkj";
export var whyDeimosBeforeContent = "home-module--whyDeimosBeforeContent--WZLGX";
export var whyDeimosBeforeHeader = "home-module--whyDeimosBeforeHeader--W3qVa";
export var whyDeimosBeforeList = "home-module--whyDeimosBeforeList--Ztxgs";
export var goingGoogleSection1Text = "home-module--goingGoogleSection1Text--Miktf";
export var whyDeimosAfterText = "home-module--whyDeimosAfterText--DFiti";
export var goingGoogleSection2Text = "home-module--goingGoogleSection2Text--VeAZs";
export var whyDeimosBeforeImage = "home-module--whyDeimosBeforeImage--drPaW";
export var whyDeimosAfterContainer = "home-module--whyDeimosAfterContainer--q4BmD";
export var whyDeimosAfter = "home-module--whyDeimosAfter--SrUIs";
export var whyDeimosAfterContent = "home-module--whyDeimosAfterContent--kA8TA";
export var whyDeimosButtonContainer = "home-module--whyDeimosButtonContainer--mOr0Q";
export var whyDeimosAfterHeader = "home-module--whyDeimosAfterHeader--hAXn8";
export var whyDeimosAfterImage = "home-module--whyDeimosAfterImage--dWo1w";
export var whyDeimosButton = "home-module--whyDeimosButton--GWK9h";
export var ourTechStackWrapper = "home-module--ourTechStackWrapper--aOofn";
export var ourTechStackMobileImage = "home-module--ourTechStackMobileImage--myjkR";
export var mobileText = "home-module--mobileText--oOylX";
export var ourStrategicPartnersButton = "home-module--ourStrategicPartnersButton--WXhKy";
export var checkVacanciesDescription = "home-module--checkVacanciesDescription--texyN";
export var checkVacanciesButton = "home-module--checkVacanciesButton--JIl+b";
export var aboutUsWrapper = "home-module--aboutUsWrapper--w7V0x";
export var aboutUsContainer = "home-module--aboutUsContainer--m7oN4";
export var aboutUsContent = "home-module--aboutUsContent--qlpSl";
export var whatSetsUsApartWrapper = "home-module--whatSetsUsApartWrapper--lwwqx";
export var whatSetsUsApartBody = "home-module--whatSetsUsApartBody--HbHkc";
export var whatSetsUsApartContent = "home-module--whatSetsUsApartContent--Y+d6g";
export var whatSetsUsApartHeader = "home-module--whatSetsUsApartHeader--N+L3T";
export var whatSetsUsApartSubheader = "home-module--whatSetsUsApartSubheader---webn";
export var whatSetsUsApartDescription = "home-module--whatSetsUsApartDescription--Q-Igt";
export var whatSetsUsApartButton = "home-module--whatSetsUsApartButton--M+ksW";
export var contactFormWrapper = "home-module--contactFormWrapper--FyxKI";
export var contactFormContainer = "home-module--contactFormContainer--7i0EX";
export var contactFormTextContainer = "home-module--contactFormTextContainer---JeL+";
export var contactFormText = "home-module--contactFormText--OZqYL";
export var contactFormButton = "home-module--contactFormButton--srka4";
export var successStoriesWrapper = "home-module--successStoriesWrapper--ENion";
export var customerSuccessWrapper = "home-module--customerSuccessWrapper--cZV8j";
export var customerSuccessStoriesContainer = "home-module--customerSuccessStoriesContainer--xMUHt";
export var customerSuccessContainer = "home-module--customerSuccessContainer--CztPw";
export var successStoryBody = "home-module--successStoryBody--mvlQE";
export var successStoryDesc = "home-module--successStoryDesc--SxWBy";
export var customerSuccessDivMobile = "home-module--customerSuccessDivMobile--0uo4O";
export var successListContainer = "home-module--successListContainer--E9dzu";
export var heroTextWrapper = "home-module--heroTextWrapper--Xsbv8";
export var clientStyle = "home-module--clientStyle--YF00i";
export var heroImageContainer = "home-module--heroImageContainer--bBP9S";
export var marginTop = "home-module--marginTop--0sy94";
export var ourTechStackDesktopImage = "home-module--ourTechStackDesktopImage--BkJt1";
export var desktopText = "home-module--desktopText--ps9TV";
export var customerSuccessDivDesktop = "home-module--customerSuccessDivDesktop--Vh17X";