import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import SEO from 'components/seo'
import { jsonId, composeClasses } from 'utils'
import HelmetExport from 'react-helmet'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import deimosIcon from '../images/deimosIcon.svg'
import * as homeStyles from '../styles/home.module.css'
import * as managedInfraStyles from '../styles/managedInfrastructure.module.css'
import {
  pageWrapper,
  breadcrumbContainer
} from '../styles/professionalServicesChildren.module.css'

export const query = graphql`
  query {
    wordPress {
      pages(where: {name: "Managed Infrastructure"}) {
        edges {
          node {
            managedInfraFieldGroup {
              approachSectionBlocks {
                approachSectionBlockHeader
                approachSectionBlockText
              }
              approachSectionHeader
              approachSectionSubheaderOne
              approachSectionSubheaderTwo
              capabilitiesSectionHeader
              capabilitiesSectionImages {
                capabilitiesSectionImage {
                  sourceUrl
                }
                capabilitiesSectionText
              }
              capabilitiesSectionSubheader
              technologiesSectionHeader
              technologiesSectionSubheader
              technologiesSectionImages {
                technologiesSectionText
                technologiesSectionImage {
                  sourceUrl
                }
              }
              challengesSectionHeader
              challengesSectionSubheader
              challengesSectionTabContent {
                tabContentHeader
                tabContentList
                tabContentImage {
                  sourceUrl
                }
                tabContentSubheader
              }
              challengesSectionTabHeaders {
                tabTitle
              }
              customersWhoTrustUsHeader
              customersWhoTrustUsImages {
                customersWhoTrustUsImage {
                  sourceUrl
                }
              }
              differenceSectionBlocks {
                differenceSectionBlockHeader
                differenceSectionBlockText
                differenceSectionBlockImage {
                  sourceUrl
                }
              }
              differenceSectionHeader
              differenceSectionSubheader
              managedInfraButtonLink
              managedInfraButtonText
              managedInfraHeaderImage {
                sourceUrl
              }
              managedInfraHeaderSubtitle
              managedInfraHeaderTitleOne
              managedInfraHeaderTitleTwo
              servicesSectionHeader
              servicesSectionImages {
                serviceSectionText
                serviceSectionImage {
                  sourceUrl
                }
              }
              servicesSectionSubheader
              transformationSectionButtonLink
              transformationSectionButtonText
              transformationSectionHeaderOne
              transformationSectionHeaderTwo
              transformationSectionText
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const ManagedInfrastructure = ({ data, pageContext }) => {
  const managedInfraData = data.wordPress.pages.edges[0].node.managedInfraFieldGroup
  const managedInfraHeaderImage = managedInfraData.managedInfraHeaderImage.sourceUrl

  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const {
    breadcrumb: { crumbs }
  } = pageContext

  const [tabIndex, setTabIndex] = useState(0)

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/professional-services`,
            'name': 'Professional Services',
            'position': 2
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/professional-services/kubernetes`,
            'name': 'Kubernetes',
            'position': 3
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 4
          }]
        })}
      </HelmetExport>
      <SEO
        description='Our Managed Infrastructure Information'
        title='Managed Infrastructure'
      />
      {/* Breadcrumbs */}
      <div className={pageWrapper}>
        <div className={breadcrumbContainer}>
          <Breadcrumb
            crumbLabel='Managed Infrastructure'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
        </div>
        {/* Hero Header */}
        <section className={homeStyles.horizontalWrapper}>
          <div className={homeStyles.hero}>
            <div className={homeStyles.heroTextWrapper}>
              <div className={homeStyles.heroTextContainer}>
                <h1 className={managedInfraStyles.primaryHeader}>
                  {managedInfraData.managedInfraHeaderTitleOne}
                </h1>
                <h1 className={managedInfraStyles.secondaryHeader}>
                  {managedInfraData.managedInfraHeaderTitleTwo}
                </h1>
                <p>{managedInfraData.managedInfraHeaderSubtitle}</p>
              </div>

              <div className={homeStyles.heroLinks}>
                <Link
                  className={managedInfraStyles.callBackButton}
                  target={'_blank'}
                  to={managedInfraData.managedInfraButtonLink}
                >
                  {managedInfraData.managedInfraButtonText}
                </Link>
              </div>
            </div>

            <div className={homeStyles.heroImageContainer}>
              <img
                alt={`managed infra image`}
                className={homeStyles.heroImageDesktop}
                height='380'
                src={managedInfraHeaderImage}
                width='380'
              />
            </div>
          </div>
        </section>
        {/* Customers who trust us section */}
        <section className={homeStyles.clientWrapper}>
          <div className={managedInfraStyles.clientsContainer}>
            <h1 className={composeClasses(homeStyles.clientHeaderWrapper, managedInfraStyles.customersHeader, managedInfraStyles.sectionHeader)}>
              {managedInfraData.customersWhoTrustUsHeader}
            </h1>

            <div className={managedInfraStyles.clientImgWrapper}>
              {managedInfraData.customersWhoTrustUsImages.map((item, index) => (
                <div
                  className={managedInfraStyles.clientLogo}
                  key={index.toString()}>
                  <img
                    alt='client logo'
                    height='57'
                    src={item.customersWhoTrustUsImage.sourceUrl}
                    width='180' />
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* Services Section */}
        <section className={managedInfraStyles.servicesWrapper}>
          <div className={managedInfraStyles.innerRowContainer}>
            <div className={managedInfraStyles.titleWrapper}>
              <p className={composeClasses(managedInfraStyles.sectionHeader, managedInfraStyles.servicesHeader)}>
                {managedInfraData.servicesSectionHeader}
              </p>
              <h2 className={managedInfraStyles.primaryHeader}>
                {managedInfraData.servicesSectionSubheader}
              </h2>
            </div>

            <div className={managedInfraStyles.serviceImageWrapper}>
              {managedInfraData.servicesSectionImages.map((item, index) => (
                <div
                  className={managedInfraStyles.serviceLogo}
                  key={index.toString()}>
                  <img
                    alt='service icon'
                    src={item.serviceSectionImage.sourceUrl} />
                  <p>{item.serviceSectionText}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* Challenges Tabs */}
        <div className={managedInfraStyles.challengesWrapper}>
          <div className={managedInfraStyles.challengesTitleWrapper}>
            <p className={composeClasses(managedInfraStyles.sectionHeader, managedInfraStyles.servicesHeader)}>
              {managedInfraData.challengesSectionHeader}
            </p>
            <h2 className={managedInfraStyles.primaryHeader}>
              {managedInfraData.challengesSectionSubheader}
            </h2>
          </div>
          <div className={managedInfraStyles.tabSection}>
            <Tabs
              onSelect={index => setTabIndex(index)}
              selectedIndex={tabIndex}>
              <TabList className={managedInfraStyles.tabList}>
                { managedInfraData.challengesSectionTabHeaders.map((info, key) => {
                  return (
                    <Tab
                      className={managedInfraStyles.tab}
                      key={key.toString()}
                    >
                      <p>{info.tabTitle}</p>
                    </Tab>
                  )
                })}
              </TabList>
              {managedInfraData.challengesSectionTabContent.map((detail, key) => (
                <TabPanel
                  className={managedInfraStyles.tabPanel}
                  key={key.toString()}
                >
                  <div className={managedInfraStyles.tabPanelFlex}>
                    <div className={managedInfraStyles.tabContentLeft}>
                      <p className={composeClasses(homeStyles.sectionHeader, managedInfraStyles.customersHeader)}>
                        {detail.tabContentHeader}
                      </p>
                      <h2 className={composeClasses(homeStyles.sectionSubHeader, managedInfraStyles.tabSubheader)}>
                        {detail.tabContentSubheader}
                      </h2>
                      <img
                        alt='client logo'
                        height='57'
                        src={detail.tabContentImage.sourceUrl}
                        width='180' />
                    </div>
                    <div className={managedInfraStyles.tabContentRight}>
                      <div dangerouslySetInnerHTML={{ __html: detail.tabContentList }} />
                    </div>
                  </div>
                </TabPanel>
              ))}
            </Tabs>
          </div>
        </div>
        {/* Capabilities Section */}
        <section className={homeStyles.clientWrapper}>
          <div className={managedInfraStyles.innerColumnContainer}>
            <div className={managedInfraStyles.clientsContainer}>
              <p className={homeStyles.sectionHeader}>
                {managedInfraData.capabilitiesSectionHeader}
              </p>
              <h2 className={composeClasses(homeStyles.sectionSubHeader, managedInfraStyles.capabilitiesSubheader)}>
                {managedInfraData.capabilitiesSectionSubheader}
              </h2>

              <div className={managedInfraStyles.capabilitiesImageWrapper}>
                {managedInfraData.capabilitiesSectionImages.map((item, index) => (
                  <div
                    className={managedInfraStyles.capabilitiesIcon}
                    key={index.toString()}>
                    <img
                      alt='client logo'
                      height='57'
                      src={item.capabilitiesSectionImage.sourceUrl}
                      width='180' />
                    <p>{item.capabilitiesSectionText}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        {/* Technologies Section */}
        <section className={homeStyles.clientWrapper}>
          <div className={managedInfraStyles.innerColumnContainer}>
            <div className={managedInfraStyles.clientsContainer}>
              <p className={homeStyles.sectionHeader}>
                {managedInfraData.technologiesSectionHeader}
              </p>
              <h2 className={composeClasses(homeStyles.sectionSubHeader, managedInfraStyles.capabilitiesSubheader)}>
                {managedInfraData.technologiesSectionSubheader}
              </h2>

              <div className={managedInfraStyles.capabilitiesImageWrapper}>
                {managedInfraData.technologiesSectionImages.map((item, index) => (
                  <div
                    className={managedInfraStyles.capabilitiesIcon}
                    key={index.toString()}>
                    <img
                      alt='client logo'
                      height='57'
                      src={item.technologiesSectionImage.sourceUrl}
                      width='180' />
                    <p>{item.technologiesSectionText}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        {/* Approach Section */}
        <section className={composeClasses(managedInfraStyles.challengesWrapper, managedInfraStyles.approachSection)}>
          <div className={managedInfraStyles.innerColumnContainer}>
            <div className={managedInfraStyles.approachTitleWrapper}>
              <p className={composeClasses(managedInfraStyles.sectionHeader, managedInfraStyles.servicesHeader)}>
                {managedInfraData.approachSectionHeader}
              </p>
              <h2 className={managedInfraStyles.primaryHeader}>
                {managedInfraData.approachSectionSubheaderOne}
              </h2>
              <h2 className={managedInfraStyles.secondaryHeader}>
                {managedInfraData.approachSectionSubheaderTwo}
              </h2>
            </div>
            <div className={managedInfraStyles.approachBlocks}>
              {managedInfraData.approachSectionBlocks.map((item, index) => (
                <div
                  className={managedInfraStyles.approachBlock}
                  key={index.toString()}>
                  <img
                    alt='deimosFooterIcon'
                    src={deimosIcon}
                    width='40'
                  />
                  <h4>{item.approachSectionBlockHeader}</h4>
                  <p>{item.approachSectionBlockText}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* Difference Section */}
        <section className={composeClasses(managedInfraStyles.challengesWrapper, managedInfraStyles.differenceSection)}>
          <div className={managedInfraStyles.innerColumnContainer}>
            <div className={managedInfraStyles.approachTitleWrapper}>
              <p className={composeClasses(managedInfraStyles.sectionHeader, managedInfraStyles.servicesHeader)}>
                {managedInfraData.differenceSectionHeader}
              </p>
              <h2 className={managedInfraStyles.primaryHeader}>
                {managedInfraData.differenceSectionSubheader}
              </h2>
            </div>
            <div className={managedInfraStyles.approachBlocks}>
              {managedInfraData.differenceSectionBlocks.map((item, index) => (
                <div
                  className={managedInfraStyles.differenceBlock}
                  key={index.toString()}>
                  <img
                    alt='deimosFooterIcon'
                    src={item.differenceSectionBlockImage.sourceUrl}
                    width='40'
                  />
                  <h5>{item.differenceSectionBlockHeader}</h5>
                  <p>{item.differenceSectionBlockText}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* Transformation Section */}
        <section className={composeClasses(managedInfraStyles.challengesWrapper, managedInfraStyles.transformationSection)}>
          <div className={managedInfraStyles.innerRowContainer}>
            <div className={managedInfraStyles.transformationContainer}>
              <div className={managedInfraStyles.transformationContent}>
                <h2 className={managedInfraStyles.primaryHeader}>
                  {managedInfraData.transformationSectionHeaderOne}
                </h2>
                <h2 className={managedInfraStyles.secondaryHeader}>
                  {managedInfraData.transformationSectionHeaderTwo}
                </h2>
                <p>{managedInfraData.transformationSectionText}</p>
                <div className={homeStyles.heroLinks}>
                  <Link
                    className={managedInfraStyles.callBackButton}
                    target={'_blank'}
                    to={managedInfraData.transformationSectionButtonLink}
                  >
                    {managedInfraData.transformationSectionButtonText}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

ManagedInfrastructure.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default ManagedInfrastructure
